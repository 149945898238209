import { BigNumber } from '@0x/utils';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { Ticker } from '@derivadex/types';
import useLocalStorage from 'hooks/useLocalStorage';
import { useScreenSize } from 'hooks/useScreenSize';
import { HiStar } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFavoriteTickers } from 'store/profile/selectors';
export default function FavoriteTickers() {
    const navigate = useNavigate();
    const isDesktopView = useScreenSize();
    const tickers = useSelector(getFavoriteTickers);
    const [_cachedMarket, setCachedMarket] = useLocalStorage<string>('selectedMarket', 'ETHP');

    function isNegative(ticker: Ticker) {
        return new BigNumber(ticker.close).isLessThan(ticker.open || 0);
    }

    function selectMarket(symbol: string) {
        setCachedMarket(symbol);
        navigate(`/trade/${symbol}`);
    }

    return (
        <>
            {
                <Flex
                    alignSelf="center"
                    color="whiteAlpha.800"
                    fontFamily="SF-Pro-Text"
                    px="2rem"
                    py="0.25rem"
                    fontWeight={500}
                    direction="row"
                >
                    {tickers.length > 0 && (
                        <Icon color="purple.500" fontSize="xl" mt="0.23rem" mr="0.5rem">
                            <HiStar />
                        </Icon>
                    )}
                    {tickers
                        .slice() // Create a copy to avoid mutating the original array
                        .sort((a, b) => a.symbol.localeCompare(b.symbol)) // Alphabetical sorting
                        .map((ticker) => (
                            <Flex
                                key={ticker.symbol}
                                direction="row"
                                cursor="pointer"
                                _hover={{
                                    color: 'white',
                                }}
                                onClick={() => selectMarket(ticker.symbol)}
                                mx={'0.75rem'}
                            >
                                {ticker.symbol}{' '}
                                <Text color={isNegative(ticker) ? '#f04351' : '#70c6a3'} pl="0.25rem">
                                    {`${isNegative(ticker) ? '-' : '+'}${new BigNumber(ticker.close || 0)
                                        .minus(ticker.open || 0)
                                        .dividedBy(ticker.open || 1)
                                        .multipliedBy(100)
                                        .abs()
                                        .toFixed(2)}%`}
                                </Text>
                            </Flex>
                        ))}
                </Flex>
            }
        </>
    );
}
