import { Box, CircularProgress } from '@chakra-ui/react';
import { ChainConnectionStatus } from '@derivadex/types';
import MarketplaceFooter from 'pages/MarketplaceFooter';
import { lazy, ReactNode, Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
    isDepositCollateralsDialogActive,
    isMarketsDialogActive,
    isStrategiesDialogActive,
    isWalletDialogActive,
    isWithdrawDdxDialogActive,
    isWithdrawTokensDialogActive,
} from 'store/ui/selectors';
import { getNetworkStatus } from 'store/web3/selectors';
import { useAccount } from 'wagmi';

import EnableOneClickTradingDialog from './Marketplace/BuySell/Tabs/EnableOneClickTradingDialog';
import FavoriteTickers from './Stats/FavoriteTickers';
import Strategies from './Strategies/Strategies';

const Header = lazy(() => import('./Header'));
const Wallets = lazy(() => import('./Wallet/Wallets'));
const SwitchNetwork = lazy(() => import('./Wallet/SwitchNetwork'));
const Markets = lazy(() => import('./Markets'));
const Deposits = lazy(() => import('./Deposits'));
const Validations = lazy(() => import('./Toasts/Validations'));
const WithdrawTokensContainer = lazy(() => import('./WithdrawTokens/WithdrawTokensContainer'));
const WithdrawDDXContainer = lazy(() => import('./WithdrawDDX/WithdrawDDXContainer'));
const SecurityWarningAlert = lazy(() => import('./Alerts/SecurityWarningAlert'));

export default function Layout({ children }: { children: ReactNode }) {
    const shouldLoadWallets = useSelector(isWalletDialogActive);
    const shouldLoadMarkets = useSelector(isMarketsDialogActive);
    const shouldLoadDeposits = useSelector(isDepositCollateralsDialogActive);
    const shouldLoadWithdrawTokensContainer = useSelector(isWithdrawTokensDialogActive);
    const shouldLoadWithdrawDDXContainer = useSelector(isWithdrawDdxDialogActive);
    const shouldLoadStrategies = useSelector(isStrategiesDialogActive);
    const networkStatus = useSelector(getNetworkStatus);
    const { isConnected } = useAccount();
    return (
        <>
            <SecurityWarningAlert />
            <Header />
            <FavoriteTickers />
            <Box as="main" h="calc(100vh - 5rem)">
                {children}
            </Box>
            <Box borderRadius="base" bgColor="card.100" p="0rem">
                <MarketplaceFooter />
            </Box>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadWallets && <Wallets />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadMarkets && <Markets />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadStrategies && <Strategies />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {networkStatus === ChainConnectionStatus.INCORRECT && <SwitchNetwork />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadDeposits && <Deposits />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadWithdrawTokensContainer && <WithdrawTokensContainer />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadWithdrawDDXContainer && <WithdrawDDXContainer />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{isConnected && <Validations />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {isConnected && <EnableOneClickTradingDialog />}
            </Suspense>
        </>
    );
}
